<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">C2 Setup</h1>
                <template v-if="isEnabled">
                    <p>C2 access is ready.</p>
                </template>
                <template v-if="!isEnabled && webauthzLink">
                    <p><v-btn @click="redirectToC2">Setup C2</v-btn></p>
                </template>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        isViewReady: false,
        isEnabled: null,
        webauthzLink: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
    },
    watch: {
    },
    methods: {
        async loadC2Setup() {
            try {
                this.$store.commit('loading', { loadC2Setup: true });
                const response = await this.$client.main().service.getC2Config();
                this.isEnabled = response?.isEnabled ?? false;
                if (response?.webauthz) {
                    this.webauthzLink = response.webauthz;
                }
            } catch (err) {
                console.error('loadC2Setup failed', err);
            } finally {
                this.$store.commit('loading', { loadC2Setup: false });
                this.isViewReady = true;
            }
        },
        redirectToC2() {
            window.location = this.webauthzLink;
        },
    },
    mounted() {
        this.loadC2Setup();
    },
};
</script>
